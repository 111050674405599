import { AcimaCreditCheckout } from './acima-credit-checkout';
import { getSetting } from '@woocommerce/settings';

const settings = getSetting('acima_credit_data', {});

const processAcimaPayment = async (paymentData) => {
    try {
        const response = await fetch('/wp-json/wc-acima-credit/v1/process-payment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-WP-Nonce': window.acimaBlockCheckout.nonce || '',
            },
            body: JSON.stringify({
                order_id: paymentData.transactionData.orderId,
            }),
        });

        const data = await response.json();

        if (data.success) {
            const checkout = new AcimaCreditCheckout(
                settings,
                data.customer_data,
                data.transaction_data,
                data.thank_you_page,
                paymentData.transactionData.orderId,
                paymentData.orderNonce
            );
            const result = await checkout.initCheckout();

            return {
                paymentMethodId: 'acima_credit',
                leaseId: result.leaseId,
            };
        } else {
            throw new Error(data.message || 'Payment processing failed.');
        }
    } catch (error) {
        throw {
            message: error.message || 'An unknown error occurred during Acima payment processing',
            details: error
        };
    }
};

export { processAcimaPayment };
